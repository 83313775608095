.termsAndConditions {
    margin-top: 20px;
}

.footerText {
    color: #726d68;
    font-size: 17px;
    font-weight: 500;
}

#footer {
    height: 120px;
    width: 100%;
    background-color: #f2f2f2;
    position: absolute;
    bottom: 0;
}

.footer {
    background-color: #f2f2f2;
}