@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(50vh - 80px);
}

.instaContainer {
    position: relative;
    top: 0;
    left: 0;
}

.profileImg {
    border-radius: 50%;
    max-height: 70px;
    position: relative;
    border: 1px black solid;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.hoverImg {
    max-height: 70px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    border: 1px black solid;
    transition: .5s ease;
    left: 0;
    right: 0;
    margin: auto;
}

.hoverImg:hover {
    opacity: 0.7;
}

.instaName, .insta {
    color: inherit;
    text-decoration: none;
}

.instaName:hover {
    color: #726d68;
}