.parallax {
    /* Full height */
    height: 350px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff6f7;
}

.logo {
    position: relative;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    max-height: 304px;
}

.testDiv {
    height: 1000px;
    font-size: 36px
}