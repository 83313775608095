.contactIcon {
    max-width: 80px;
    max-height: 50px;
    transition: transform .2s;
}

.contactIcon:hover {
    transform: scale(1.2);
}

.inner {
    padding: 40px
}

a {
    color: #726d68 !important;
}

a:hover {
    cursor: pointer;
    color: #242322 !important;
}

.contactPage {
    height: 74vh;
    margin: auto !important;
}

@media (max-width: 768px) {
    .contactPage {
        height: 700px;
    }
}


@media (max-width: 575.98px) {
    .contactPage {
        height: 850px;
    }
}

.email {
    word-break: break-all;
}