.priceList {
    min-height: 76.5vh;
    background-image:  url("assets/background/CloseUp.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: saturation;
}

.priceTitle {
    background-color: white;
    border-radius: 4px;
    box-shadow: 1px 1px 4px black;
}

.card {
    box-shadow: 1px 1px 4px black;
}